.burger-button {
  position: relative;
  top: 4px;
}

.app-bar-custom {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: -7px;
}

.nav-bar-currency {
  margin-left: 20px;
  font-size: 14px;
}

.nav-bar-currency strong {
  padding: 7px 10px;
  border-radius: 8px;
  font-weight: 400;
  margin-left: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.nav-bar-currency.period {
  margin-right: 20px;
}

@media screen and (max-width:767px) {
  .nav-bar-currency span {
    display: block;
    padding-bottom: 4px;
  }

  .nav-bar-currency strong {
    margin-left: 0;
  }
}

@media screen and (max-width:480px) {
  .nav-bar-currency {
    display: none;
  }
}

.dashboard-row-head {
  /* padding-top: 25px; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.dashboard-row-head h2 {
  font-weight: 400;
  margin-right: 30px;
}

.MuiPaper-rounded.MuiMenu-paper {
  box-shadow: 0px 1px 5px -3px rgba(0, 0, 0, 0.1) !important;
}

.sidebar-head-logo img {
  max-width: 100px;
  margin-bottom: -10px;
}

.sidebar-menu-list .MuiListItemText-root {
  white-space: normal;
}

.sidebar-menu-list .MuiListItemText-root span {
  line-height: 1.3;
  display: inline-block;
}

.sidebar-button-logout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.sidebar-button-logout button {
  display: inline-flex;
  align-items: center;
  color: #E91F63;
  font-size: 15px;
  border: 0;
  border-radius: 0;
  background: none;
  cursor: pointer;
  padding: 5px;
  transition: all .3s;
}

.sidebar-button-logout button:hover {
  color: #344767;
}

.sidebar-button-logout button svg {
  margin-right: 5px;
  margin-top: 2px;
}

.sidebar-logo {
  text-align: center;
  width: 100%;
}

.sidebar-logo img {
  width: 130px;
  height: auto;
}

.sidebar-logo img.miniSidenav {
  width: 35px;
  height: auto;
}

.DataTableUsers thead th:last-child {
  width: 100px;

}

.DataTableUsers thead th:last-child {
  text-align: center;
}

.DataTableUsers thead th>div {
  color: #000;
  display: flex;
  align-items: center;
  font-size: 14px;

  text-transform: capitalize;
}

.DataTableUsers thead th>div>div {
  position: relative;
  left: 0;
  right: auto;
  top: 7px;
  margin-left: 10px;
  display: block;
  color: #000;
  width: 20px;
  height: 30px;
}

.DataTableUsers thead th:last-child div {
  text-align: center;
  display: inline-block;
}

.DataTableUsers tbody tr {
  cursor: pointer;
}

.DataTableUsers tbody tr:hover {
  background: rgba(0, 0, 0, 0.01);
}

.DataTableUsers thead th:last-child div div:last-child {
  display: none;
}

.DataTableUsers tbody td:last-child {
  text-align: center;
}

.profile-header {
  padding: 30px 20px;
}

.profile-header .title {
  text-transform: capitalize;
}

.profile-header .list-profile-items {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: -20px;
  padding-top: 20px;
}

.profile-header .list-profile-items li {
  margin-right: 20px;
  font-size: 15px;
  margin-bottom: 20px;
}


.profile-header-top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.profile-header .userStatus.active {
  color: #28B535;
}

@media screen and (max-width:1024px) {
  .profile-header .list-profile-items li {
    margin-right: 30px;

  }

  .profile-header .list-profile-items li strong {
    display: block;
  }
}

@media screen and (max-width:767px) {
  .profile-header .list-profile-items {
    flex-wrap: wrap;
  }

  .profile-header .list-profile-items li {
    margin-right: 0px;
    width: 100%;
    display: block;

  }


}

.popupEditEntity {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 30px;
  z-index: 9999;
  background: rgba(255, 255, 255, .5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupEditEntity-content {
  width: 100%;
  max-width: 750px;
  position: relative;
  background: #fff;
  padding: 70px 50px;
  border-radius: 0.75rem;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%);
}

.popupEditEntity-content .closePopup {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
}

.popupEditEntity-content .closePopup {
  background: #fff;
  cursor: pointer;
  display: inline-block;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  color: #000;
  font-size: 30px;
}


.popupEditEntity-inner {
  font-size: 20px;
}

.custom-select {
  font-size: 15px;
}

.custom-select>p {
  font-size: 13px;
}

.export-button-wrapper>a {
  display: none;
}

.export-button-wrapper>button {
  display: inline-block;
  padding: 10px;
  font-size: 17px;
  cursor: pointer;
  min-width: 66px;
  text-align: center;
}

.loader {
  text-align: center;
  height: 17px;
}

.loader span {
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: inline-block;
  animation: spin 2s linear infinite;
}

.section-loader .loader {

  height: 60px;
}

.section-loader .loader span {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.export-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
}

.export-dropdown ul {
  list-style: none;
}

.mui-dropdown-custom .MuiPaper-root {
  box-shadow: 0rem 0.15rem 0.375rem -0.0625rem rgb(0 0 0 / 30%) !important;
}

.button-export-inner {
  font-size: 14px !important;
  padding: 6px 14px !important;
  border-radius: 4px !important;
  transition: all .3s;
}

.button-export-inner:hover {
  background-color: #f0f2f5 !important;
  color: #344767 !important;
}

.list-buttons {
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
  list-style: none;
  margin-bottom: -10px;
}

.list-buttons>li {
  padding: 0 10px;
  margin-bottom: 10px;
}

.list-buttons>li button {
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%);
}

.list-buttons>li button.active {
  background: #365DC7;
  color: #fff;
}

.list-buttons>li button:hover {
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%);
}

.list-buttons>li button:focus {
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%) !important;
}

.list-buttons>li button.active:focus {
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%) !important;
  background: #365DC7;
  color: #fff;
}

.header-sub-dashboard {
  margin: 0 !important;
}

.header-sub-dashboard-menu {
  margin-left: auto;
}

@media screen and (max-width:767px) {
  .header-sub-dashboard {
    display: block !important;
  }
  .header-sub-dashboard-menu { margin: 10px -10px 0 !important; }
}

.dashboard-custom-date-popup { padding: 40px; }
.dashboard-custom-date-popup .popup-head { padding-bottom: 20px; }
.dashboard-custom-date-popup .cols { display: flex; flex-wrap: wrap; margin: 0 -10px; }
.dashboard-custom-date-popup .col-1of2 { width: 50%; padding: 0 10px; }
.dashboard-custom-date-popup .col-1of2 > div { width: 100%; }
.dashboard-custom-date-popup .cols small { display: block; }
.dashboard-custom-date-popup input { width: 100%; }
.dashboard-custom-date-popup .popup-action { padding-top: 30px; }

@media screen and (max-width:767px) {
  .dashboard-custom-date-popup .col-1of2 { width: 100%; }
}