.login-page {
     height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 60px;
    overflow: auto;
}

.login-page-outer {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
 }

.login-page-head {
    text-align: center;
    padding: 30px;
}

.login-card-head-logo {
    text-align: center;
    padding: 30px 0 0;
}

.login-page-head {
    font-size: 16px;
}

.login-page-title {
    font-size: 24px;
    margin-bottom: 4px;
}

.login-page-body {
    max-width: 500px;
    margin: 0 auto;
}

.generalError {
    color: red;
    font-size: 14px;
    text-align: center;
}

.login-page-body .loginError {
    color: red;
    font-size: 14px;
    text-align: center;
}

.login-bg-top {
    position: absolute;
    top: 0;
    left: 0;
    opacity: .4;
    width: 50%;
}

.login-bg-top svg {
    width: 100%;
}

.login-bg-top img {
    width: 100%;
}

.login-bg-bottom img {
    width: 100%;
}

.login-bg-bottom {
    position: absolute;
    bottom: -20px;
    right: 0;
    opacity: .4;
    width: 50%;
}

.login-bg-bottom svg {
    width: 100%;
}

@media screen and (max-width:767px) {
    .login-bg-top {
        display: none;
    }

    .login-bg-bottom {
        display: none;
    }
}

.login-page-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 10px 30px;
    font-size: 16px;
    text-align: center;
}

@media screen and (max-width:767px) {
    .login-page {
        flex-wrap: wrap;
    }

    .login-page-footer {
        padding: 40px 20px 0;
        position: static;
        font-size: 14px;

    }
}